<template>
  <div class="card">
    <h3 class="card-title">Ver procedimiento</h3>
    <template v-if="noticia">
      <div class="cols2">
        <pd-input
          class="my-0"
          readonly
          classInput="form-control readonly text-bold text-success"
          v-model="noticia.titulo"
          label="Título"
          id="titulo"
          placeholder="Sin datos"
        ></pd-input>
        <pd-input
          class="my-0"
          readonly
          classInput="form-control readonly text-bold text-secondary"
          v-model="desde_formatted"
          label="Desde"
          id="desde"
          placeholder="Sin datos"
        ></pd-input>
      </div>
      <div class="cols2 mt-2" v-if="noticia.requiere_firma">
        <pd-input
          class="my-0"
          readonly
          classInput="form-control readonly text-bold text-success"
          v-model="usuariosNotificados"
          label="Usuarios notificados"
          id="usuarios_notificados"
          placeholder="Sin datos"
        ></pd-input>
      </div>
      <div class="my-2" v-if="noticia.requiere_firma">
        <button class="btn btn-info" @click="openFirmados = true">Ver usuarios que firmaron</button>
      </div>
      <h4>Cartelera</h4>
      <hr />
      <div class="noticias">
        <div class="card" :class="classNoticia">
          <h4 class="card-title">{{ noticia.titulo }}</h4>
        </div>
      </div>
      <h4 class="mt-2">Procedimiento completo</h4>
      <hr />
      <div>
        <h2 class="card-title text-center mt-2 mb-2">{{ noticia.titulo }}</h2>
        <div class="d-flex" v-if="noticia.archivo">
          <embed
            :src="'data:application/pdf;base64,' + noticia.contenido_archivo"
            style="width: 100%; height: 29.7cm"
            frameborder="0"
          />
        </div>
        <div style="padding: 0 5px" v-html="noticia.contenido" v-else></div>
      </div>
      <div class="d-flex space-between mt-1">
        <button class="btn btn-warning" @click="editNoticia">
          Editar procedimiento
        </button>
        <button class="btn btn-error" @click="eliminarNoticia">
          Eliminar procedimiento
        </button>
      </div>
      <Modal v-if="openFirmados"  @close="openFirmados = false">
      <div slot="modal-body"  style="min-width: 80vw">
        <h3 class="card-title">Usuarios notificados</h3>
        <table-list
        :showFilter="true"
        :data="noticia.usuarios_notificables"
        :header="headerUsuariosFirmados"
        :body="bodyUsuariosFirmados"
      ></table-list>
      </div>
      </Modal>
    </template>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import { NoticiasServices } from "../../noticias/services/NoticiasServices";
import { UsuarioFirmadoService } from "../services/ProcedimientosServices"
import Modal from "../../../components/elements/Modal.vue";
import TableList from "@/components/elements/TableList";
export default {
    name: "VerNoticia",
    data() {
        return {
            loading: false,
            noticia: null,
            openFirmados: false,
            headerUsuariosFirmados: UsuarioFirmadoService.headers(),
            bodyUsuariosFirmados: UsuarioFirmadoService.body(),
        };
    },
    components: { Modal, TableList },
    computed: {
        classNoticia() {
            let clase = "";
            if (this.noticia.tipo) {
                clase = `card-${this.noticia.tipo}`;
            }
            return clase;
        },
        dejar_fijo() {
            if (this.noticia.fijo === 1) {
                return "SI";
            }
            return "NO";
        },
        desde_formatted() {
            return NoticiasServices.normalDate(this.noticia.desde);
        },
        hasta_formatted() {
            return NoticiasServices.normalDate(this.noticia.hasta);
        },
        usuariosNotificados(){
          const totalUsuarios = this.noticia.usuarios_notificables.length
          const notificados = this.noticia.usuarios_notificables.filter((usuario) => usuario.firmado === 1).length
          return `${notificados} de ${totalUsuarios}`
        }
    },
    mounted() {
        const noticiaId = this.$route.params.id;
        this.loading = true;
        NoticiasServices.api
            .find(noticiaId)
            .then((response) => {
            this.noticia = response.noticia;
        })
            .catch((error) => {
            Swal.fire("Error", `${error}`, "error");
        })
            .finally(() => (this.loading = false));
    },
    methods: {
        editNoticia() {
            this.$router.push({
                name: "EditarProcedimiento",
                params: { id: this.noticia.id },
            });
        },
        eliminarNoticia() {
            Swal.fire({
                title: "Confirme",
                html: `<p>Está seguro que desea eliminar <b>${this.noticia.titulo}</b>?</p>`,
                icon: "question",
                showConfirmButton: true,
                confirmButtonText: "Eliminar",
                showCancelButton: true,
                cancelButtonText: "Cancelar",
            }).then((result) => {
                if (result.isConfirmed) {
                    NoticiasServices.api.delete(this.noticia).then(() => {
                        Swal.fire("Éxito", "<p>Se ha eliminado con éxito</p>", "success");
                        this.$router.push({ name: "ListarProcedimientosAdmin" });
                    });
                }
            });
        },
    },
};
</script>